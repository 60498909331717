import { useContext } from "react";
import { ViewContext } from '../App';
import { useTranslation } from 'react-i18next';

const Section1 = () => {
  const { fontSize } = useContext(ViewContext);
  const { t, i18n } = useTranslation();

  return (
    <>
      <div
        className="section-container section-container-1 relative bg-black text-white flex flex-col items-center justify-center"
        style={{ height: 118 + (fontSize / 100 * 118 / 2) + 'vh', minHeight: 900 }}
      >
        <div className="flex flex-col justify-between -mt-24 md:-mt-16" style={{ height: '70vh' }}>
          <div>
            <img src="/assets/logo.png" alt="Naked Bottle Project Logo" className="mb-4 mx-auto" width="286" />
            <h1 className="eastman-compressed text-custom-h1 mb-0 text-center font-black uppercase" style={{ letterSpacing: 2 }}>{t('DROP THE BOX')}</h1>
            <h2 className="eastman-compressed text-custom-h4 text-center font-black mt-4 md:mt-0 less-packing-letter-style">
            {t('LESS PACKAGING')}<br />{t('FOR A BETTER TOMORROW')}</h2>
          </div>
          <div className="arrow-container flex flex-col items-center justify-center py-4 ">
            <p className="dm-sans block mb-8 md:mb-8 md:text-lg text-center">{t('Scroll to start a ripple of change')}</p>
            <img src="assets/arrow-down.svg" alt="Arrow Down" />
          </div>
        </div>

        <div className="absolute w-full" style={{ bottom: -1 }}>
          <img src="assets/waterdrop-path.svg" alt="Black Water Drop" className="w-full hidden xl:block" />
          <img src="assets/waterdrop-path-768.svg" alt="Black Water Drop" className="w-full hidden md:block xl:hidden" />
          <img src="assets/waterdrop-path-425.svg" alt="Black Water Drop" className="w-full block md:hidden" />
        </div>
      </div>
    </>
  )
}

export default Section1;