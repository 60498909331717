import { motion } from "framer-motion";
import { useContext, useEffect, useRef, useState } from 'react';
import { ViewContext } from '../App';

interface Props {
  className: string;
  displayName?: boolean;
  dropdownClassName?: string;
  darkMode?: boolean;
}

const LanguageButton = ({ className, displayName = false, dropdownClassName = "", darkMode = false }: Props) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [langMenu, setLangMenu] = useState(false);
  const { vh, scrollY, language, setLanguage } = useContext(ViewContext);
  const [fill, setFill] = useState('#fff');

  useEffect(() => {
    if (
      scrollY / vh > 1 && scrollY / vh < 4.12
      || scrollY / vh > 5.5 && scrollY / vh < 6.62
      || scrollY / vh > 9.78 && scrollY / vh < 11.13
      || scrollY / vh > 12.5
    ) {
      setFill('#000');
    } else {
      setFill('#fff');
    }
  }, [scrollY]);

  return (
    <div id="open-lang-menu" className={className}>
      <button name="Language Button" onClick={() => setLangMenu(!langMenu)} className="flex flex-row items-center mx-auto">
        <svg xmlns="http://www.w3.org/2000/svg" width="20.462" height="20.462" viewBox="0 0 20.462 20.462">
          <path id="language_FILL0_wght400_GRAD0_opsz48" fill={fill} d="M90.231,196.462a10.026,10.026,0,0,1-4.016-.806,10.332,10.332,0,0,1-3.248-2.187,10.12,10.12,0,0,1-2.174-3.261A10.259,10.259,0,0,1,80,186.18a10.074,10.074,0,0,1,.793-4,10.194,10.194,0,0,1,2.174-3.236,9.985,9.985,0,0,1,3.248-2.161,10.725,10.725,0,0,1,8.032,0,9.985,9.985,0,0,1,3.248,2.161,10.194,10.194,0,0,1,2.174,3.236,10.074,10.074,0,0,1,.793,4,10.259,10.259,0,0,1-.793,4.029A10.121,10.121,0,0,1,97.5,193.47a10.333,10.333,0,0,1-3.248,2.187A10.026,10.026,0,0,1,90.231,196.462Zm0-1.484a8.513,8.513,0,0,0,1.5-2.11,12.7,12.7,0,0,0,.985-2.826H87.776a12.972,12.972,0,0,0,.959,2.762A8.716,8.716,0,0,0,90.231,194.979Zm-2.174-.307a12.227,12.227,0,0,1-1.1-2.1,15.321,15.321,0,0,1-.767-2.532H82.353a9.173,9.173,0,0,0,2.251,2.852A11.239,11.239,0,0,0,88.057,194.672Zm4.374-.026a10.241,10.241,0,0,0,3.312-1.765,9.161,9.161,0,0,0,2.366-2.839H94.3a18.529,18.529,0,0,1-.78,2.507A11.612,11.612,0,0,1,92.431,194.646Zm-10.589-6.139h4.067q-.077-.691-.09-1.241t-.013-1.087q0-.639.026-1.138t.1-1.113H81.842a7.457,7.457,0,0,0-.243,1.1,8.965,8.965,0,0,0-.064,1.151,9.912,9.912,0,0,0,.064,1.189A7.208,7.208,0,0,0,81.842,188.508Zm5.653,0h5.5q.1-.793.128-1.292t.026-1.036q0-.512-.026-.985t-.128-1.266h-5.5q-.1.793-.128,1.266t-.026.985q0,.537.026,1.036T87.494,188.508Zm7.034,0h4.092a7.21,7.21,0,0,0,.243-1.138,9.91,9.91,0,0,0,.064-1.189,8.964,8.964,0,0,0-.064-1.151,7.459,7.459,0,0,0-.243-1.1H94.554q.077.9.1,1.368t.026.882q0,.563-.038,1.061T94.528,188.508Zm-.256-6.113h3.837a8.288,8.288,0,0,0-2.315-2.941,8.82,8.82,0,0,0-3.389-1.663,11.149,11.149,0,0,1,1.087,2.046A16.635,16.635,0,0,1,94.273,182.395Zm-6.5,0h4.962a9.976,9.976,0,0,0-.946-2.622,10.463,10.463,0,0,0-1.56-2.238,5.624,5.624,0,0,0-1.381,1.816A16.514,16.514,0,0,0,87.776,182.395Zm-5.423,0h3.862a14.4,14.4,0,0,1,.716-2.468,12.143,12.143,0,0,1,1.1-2.11,8.438,8.438,0,0,0-5.678,4.578Z" transform="translate(-80 -176)" />
        </svg>
        {
          displayName &&
            <span className="ml-2 text-lg flex flex-row justify-center dm-sans uppercase" style={{ fontSize: 14 }}>
            {
              language === 'en' ? 'English' :
              language === 'zh_cn' ? '中文' :
              language === 'fr' ? 'français' :
              language === 'es' ? 'ESPAÑOL' : ''
            }
            <img src={`/assets/${darkMode ? 'white-' : ''}dropdown.svg`}
              className="ml-2" alt="Language dropdown" />
          </span>
        }
      </button>

      {
        langMenu &&
          <div
            className="fixed top-0 left-0 w-full h-full z-50 bg-transparent" 
            onClick={() => setLangMenu(false)}
          />
      }

      <motion.div
        ref={menuRef}
        animate={{ scaleY: langMenu ? 1 : 0 }}
        initial={{ scaleY: 0 }}
        className={`${dropdownClassName} border border-gray-200`}
      >
        <div onClick={() => {setLangMenu(false); setLanguage('en')}} style={{ fontSize: 14, fontWeight: 400 }} className="px-0 bg-white text-black border-b border-gray-200 py-0.5 dm-sans hover:bg-secondary hover:text-white">English</div>
        <div onClick={() => {setLangMenu(false); setLanguage('zh_cn')}} style={{ fontSize: 14, fontWeight: 400 }} className="px-0 bg-white text-black border-b border-gray-200 py-0.5 dm-sans hover:bg-secondary hover:text-white">中文</div>
        <div onClick={() => {setLangMenu(false); setLanguage('fr')}} style={{ fontSize: 14, fontWeight: 400 }} className="px-0 bg-white text-black border-b border-gray-200 py-0.5 dm-sans hover:bg-secondary hover:text-white">Français</div>
        <div onClick={() => {setLangMenu(false); setLanguage('es')}} style={{ fontSize: 14, fontWeight: 400 }} className="px-0 bg-white text-black py-0.5 dm-sans hover:bg-secondary hover:text-white">ESPAÑOL</div>
      </motion.div>
    </div>
  )
}

export default LanguageButton;