import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { ViewContext } from '../App';

let changeSlowTimeout: any;

const LeadCricle = () => {
  const { vh, scrollY } = useContext(ViewContext);
  
  const [circleY, setCircleY] = useState(vh);
  const [slow, setSlow] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const [color, setColor] = useState('rgb(0,0,0)');

  const changeSlow = (oriState: boolean, newState: boolean, force = false) => {
    if (oriState === newState) return;

    clearTimeout(changeSlowTimeout);

    if (!force && oriState === true) {
      changeSlowTimeout = setTimeout(() => {
        setSlow(newState);
      }, 200);
    } else {
        setSlow(newState);
    }
  }

  useEffect(() => {
    const start = scrollY < vh * 0.3
    const beforeSec2 = scrollY > vh * 0.3 && scrollY <= vh * 1.1;
    const arrivedSec2 = scrollY >= vh * 1.1 && scrollY < vh * 1.3;
    const goingSec3 = scrollY >= vh * 1.3 && scrollY < vh * 1.9;
    const arrivedSec3 = scrollY >= vh * 1.9 && scrollY < vh * 2.3;
    const goingSec4 = scrollY >= vh * 2.3 && scrollY < vh * 3
    const arrivedSec4 = scrollY >= vh * 3 && scrollY < vh * 3.5;
    const goingSec5 = scrollY >= vh * 3.5 && scrollY < vh * 3.8;
    const arrivedSec5 = scrollY >= vh * 3.8 && scrollY < vh * 4.6;
    const goingSec6 = scrollY >= vh * 4.6 && scrollY < vh * 5.4;
    const arrivedSec6 = scrollY >= vh * 5.4 && scrollY < vh * 6;
    const goingSec7 = scrollY >= vh * 6 && scrollY < vh * 6.4;
    const arrivedSec7 = scrollY >= vh * 6.4 && scrollY < vh * 7.2;
    const goingSec8 = scrollY >= vh * 7.2 && scrollY < vh * 8.1;
    const arrivedSec8 = scrollY >= vh * 8.1 && scrollY < vh * 9.64;
    const goingSec9 = scrollY >= vh * 9.64 && scrollY < vh * 10;
    const arrivedSec9 = scrollY >= vh * 10 && scrollY < vh * 10.4;
    const goingSec10 = scrollY >= vh * 10.4 && scrollY < vh * 10.8;
    const arrivedSec10 = scrollY >= vh * 10.8 && scrollY < vh * 11.7;
    const goingSec11 = scrollY >= vh * 11.7 && scrollY < vh * 12.6;
    const arrived11 = scrollY >= vh * 12.6;
  

    if (start) {
      changeSlow(slow, false);
      setCircleY(scrollY * -1 / 2);
      setOpacity(0);
      setColor('rgb(0, 0, 0)');
    }
    else if  (beforeSec2) {
      // console.log('beforeSec2');
      changeSlow(slow, false);
      setCircleY(scrollY * -1 / 2);
      setOpacity(1);
      setColor('rgb(0, 0, 0)');
    }
    else if (arrivedSec2) {
      // console.log('arrivedSec2');
      changeSlow(slow, true);
      setCircleY(vh >=900 ? -200 : -100);
      setOpacity(1);
      setColor('rgb(0, 0, 0)');
    }
    else if (goingSec3) {
      // console.log('goingSec3');
      changeSlow(slow, false);
      setCircleY((scrollY - vh) * -1);
      setOpacity(1);
      setColor('rgb(0, 0, 0)');
    }
    else if (arrivedSec3) {
      // console.log('arrivedSec3');
      changeSlow(slow, true);
      setCircleY(vh / 2 * -1);
      setOpacity(0);
      setColor('rgb(0, 0, 0)');
    }
    else if (goingSec4) {
      // console.log('goingSec4');
      changeSlow(slow, false);
      setCircleY((scrollY - vh * 2) * -1);
      setOpacity(1);
      setColor('rgb(0, 0, 0)');
    }
    else if (arrivedSec4) {
      // console.log('arrivedSec4');
      changeSlow(slow, true);
      setCircleY(vh / 2 * -1);
      setOpacity(0);
      setColor('rgb(0, 0, 0)');
    }
    else if (goingSec5) {
      // console.log('goingSec5');
      changeSlow(slow, false);
      setCircleY((scrollY - vh * 3) * -1);
      setOpacity(1);
      setColor('rgb(0, 0, 0)');
    }
    else if (arrivedSec5) {
      // console.log('arrivedSec5');
      changeSlow(slow, true);
      setCircleY(-50);
      setOpacity(1);
      setColor('rgb(255, 255, 255)');
    }
    else if (goingSec6) {
      // console.log('goingSec6');
      changeSlow(slow, false);
      setCircleY((scrollY - vh * 4.6) * -1 + 100);
      setOpacity(1);
      setColor('rgb(0, 0, 0)');
    }
    else if (arrivedSec6)
    {
      // console.log('arrivedSec6');
      changeSlow(slow, true);
      setCircleY((vh / 2) * -1);
      setOpacity(0);
      setColor('rgb(0, 0, 0)'); 
    }
    else if (goingSec7)
    {
      // console.log('goingSec7');
      changeSlow(slow, false);
      setCircleY((scrollY - vh * 6) * -1 - vh / 2);
      setOpacity(1);
      setColor('rgb(0, 0, 0)'); 
    }
    else if (arrivedSec7)
    {
      // console.log('arrivedSec7');
      changeSlow(slow, true);
      setCircleY(-100);
      setOpacity(1);
      setColor('rgb(255, 255, 255)'); 
    }
    else if (goingSec8)
    {
      // console.log('goingSec8');
      changeSlow(slow, true);
      setCircleY((scrollY - vh * 7.2) * -1);
      setOpacity(1);
      setColor('rgb(0, 0, 0)');
    }
    else if (arrivedSec8)
    {
      // console.log('arrivedSec8');
      changeSlow(slow, true);
      setCircleY(vh / 2);
      setOpacity(0);
      setColor('rgb(0, 0, 0)');
    }
    else if (goingSec9)
    {
      // console.log('goingSec9');
      changeSlow(slow, false, true);
      setCircleY(vh * -1 + 100);
      setOpacity(1);
      setColor('rgb(0, 0, 0)'); 
    }
    else if (arrivedSec9)
    {
      // console.log('arrivedSec9');
      changeSlow(slow, true);
      setCircleY(vh / 2 * -1);
      setOpacity(0);
      setColor('rgb(0, 0, 0)'); 
    }
    else if (goingSec10)
    {
      // console.log('goingSec10');
      changeSlow(slow, false);
      setCircleY((scrollY - vh * 10.4) * -1 - vh / 2);
      setOpacity(1);
      setColor('rgb(0, 0, 0)'); 
    }
    else if (arrivedSec10)
    {
      // console.log('arrivedSec10');
      changeSlow(slow, true);
      setCircleY(-100);
      setOpacity(1);
      setColor('rgb(255, 255, 255)'); 
    }
    else if (goingSec11)
    {
      // console.log('goingSec11');
      changeSlow(slow, true);
      setCircleY((scrollY - vh * 11.7) * -1);
      setOpacity(1);
      setColor('rgb(0, 0, 0)'); 
    }
    else if (arrived11)
    {
      // console.log('arrived11');
      changeSlow(slow, true);
      setCircleY(vh / 2 * -1);
      setOpacity(0);
      setColor('rgb(0, 0, 0)'); 
    }
  }, [scrollY, vh, slow])

  return (
    <motion.div
      id="lead-circle"
      className="lead-circle lead-circle-2"
      animate={{ y: vh + circleY, opacity, backgroundColor: color }}
      style={{ top: 0 }}
      transition={{ ease: "easeOut", duration: slow ? 0.5 : 0 }}
    />

  )
}

export default LeadCricle;