import { motion } from "framer-motion";
import Slider from 'rc-slider';
import { useContext, useState } from "react";
import { ViewContext } from "../App";
import Toggle from "./Toggle";
import { useTranslation } from 'react-i18next';


interface MenuProps {
  show: boolean;
  close: () => void;
  onSkipAnimation: () => void;
  skipAnimate: boolean;
  onChangeFont: (v: number | number[]) => void;
}

const Menu = ({ show = false, close, onSkipAnimation, skipAnimate, onChangeFont }: MenuProps) => {
  const scrollTo = (id: string, offSet: number) => {
    window.scrollTo(0, (document.getElementById(id)?.offsetTop || 0) + offSet)
    close();
  }
  const { fontSize } = useContext(ViewContext);
  const [wcagControl, setWcagControl] = useState<null | 'text' | 'motion'>(null);
  const { t, i18n } = useTranslation();

  return (
    <motion.div
      id="fullscreen-menu"
      className="fullscreen-menu absolute z-50 origin-right overflow-y-scroll"
      style={{ display: show ? 'block' : 'none', paddingBottom: fontSize > 40 ? 330 : 0 }}
      animate={{ opacity: show ? 1 : 0 }}
    >
      <button name="Menu Button" id="close-menu" className="close-menu" onClick={close}>
        <motion.img
          src="/assets/close.svg"
          width="30"
          alt="Close Menu"
          animate={{ rotate: show ? 720 : 0 }}
        />
      </button>
      <h1 className="eastman-compressed menu-title font-black text-left uppercase">
        {t('What we’re doing')}</h1>
      <ul className="eastman-compressed menu-items uppercase font-black" style={{ letterSpacing: 1.5 }}>
        <a className="hover:text-secondary" onClick={() => scrollTo('section-4', 0)} id="menu-zero-waste-to-landfill" href="#zero-waste-to-landfill">
          <li className="mb-4">{t('Zero waste to landfill')}</li>
        </a>
        <a className="hover:text-secondary" onClick={() => scrollTo('section-6', 0)} id="menu-preserve-and-protect" href="#preserve-and-protect">
          <li className="mb-4">{t('Preserve and protect')}</li>
        </a>
        <a className="hover:text-secondary" onClick={() => scrollTo('section-8', 100)} id="menu-crafting-sustainable-future" href="#crafting-sustainable-future">
          <li className="mb-4">{t('Crafting a sustainable future')}</li>
        </a>
        <a className="hover:text-secondary" onClick={() => scrollTo('section-9', 0)} id="menu-renewable-source-by-2030" href="#renewable-source-by-2030">
          <li className="mb-4">{t('RENEWABLE ELECTRICITY BY 2030')}</li>
        </a>
      </ul>

      {/* WCAG Control */}
      <div className="border-t border-white mt-6 pt-6 flex flex-col justify-start items-start mb-24">
        <button className="hover:text-secondary cursor-pointer mb-4 flex justify-between w-full" onClick={() => setWcagControl('motion')}>
          <p style={{ fontFamily: 'DM Sans', fontWeight: 600, }} className="text-lg lg:text-3xl">
            {t('Motion & Effect')}
          </p>
          <img src="/assets/motion-effect-icon.svg" className="w-6 lg:w-9" />
        </button>

        <button className="hover:text-secondary cursor-pointer mb-4 flex justify-between w-full" onClick={() => setWcagControl('text')}>
          <p style={{ fontFamily: 'DM Sans', fontWeight: 600, }} className="text-lg lg:text-3xl">
            {t('Text Size')}
          </p>
          <img src="/assets/text-size-icon.png" className="w-6 lg:w-9" />
        </button>
      </div>

      {/* WCAG Control bottom */}
      <motion.div
        animate={{ translateY: wcagControl !== null ? 0 : '100%' }}
        initial={{ translateY: 0 }}
        transition={{ type: "spring", bounce: 0 }}
        className="h-72 lg:h-[415px] w-full bg-white fixed bottom-0 left-0 flex flex-col text-center justify-between items-center py-12 lg:py-[70px] px-5"
      >
        <img
          src="/assets/close-black.svg"
          width="30"
          className="absolute top-5 right-5 cursor-pointer"
          alt="Close Menu"
          onClick={() => setWcagControl(null)}
        />

        {
          wcagControl === 'motion' &&
          <>
            <div>
              <p className="tracking-wider font-semibold text-black uppercase text-lg lg:text-3xl">{t('Motion & Effect')}</p>
              <p className="tracking-wider font-semibold mt-2 lg:mt-8 text-sm lg:text-2xl text-gray-500">{t('Turn off the motion of the user')}<br />{t('interface, including the parallax effect')}</p>
            </div>
            <div className="flex flex-row justify-between items-center">
              <label htmlFor="toggle" className="tracking-wider font-semibold mr-4 lg:mr-8 text-black uppercase text-xs lg:text-2xl">{t('Turn on or off Motion & Effect')}</label>
              <Toggle
                disabled={fontSize > 0}
                checked={!skipAnimate}
                onClick={fontSize > 0 ? () => { } : onSkipAnimation}
              />
            </div>
          </>
        }
        {
          wcagControl === 'text' &&
          <>
            <div>
              <p className="tracking-wider font-semibold text-black uppercase text-lg lg:text-3xl">{t('Text Size')}</p>
              <p className="tracking-wider font-semibold mt-2 lg:mt-8 text-sm lg:text-2xl text-gray-500">{t('Adjust the text to your preferred')}<br />{t('reading size below')}</p>
            </div>
            <div className={`font-slider-wrapper flex flex-row justify-between items-center w-48`}>
              <span className="text-lg mr-6 font-bold dm-sans" style={{ color: '#5a5a5a' }}>A</span>
              <Slider dots step={25} defaultValue={fontSize} onChange={onChangeFont} />
              <span className={`text-4xl ${fontSize === 100 ? 'ml-4' : 'ml-4'} font-bold dm-sans`} style={{ color: '#5a5a5a' }}>A</span>
            </div>
          </>
        }
      </motion.div>
    </motion.div>
  )
}

export default Menu;