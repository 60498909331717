import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from './Footer';
import LanguageButton from './LanguageButton';

interface Props {
  onPass: () => void;
}

const AgeGate = ({ onPass }: Props) => {
  // YYYY-MM-DD
  const [age, setAge] = useState<(null | number)[]>([null, null, null]);
  const [isSave, setIsSave] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const { t, i18n } = useTranslation();

  const getMyCountryCode = async () => {
    try {
      const myLocationRes = await fetch('https://api.ipgeolocation.io/ipgeo?apiKey=77f786f020a946efa9fb2800c8886f9c');
      const myLocationObj = await myLocationRes.json();
      return myLocationObj.country_code2;
    } catch (e) {
      return 'US'
    }
  }

  const urlLanguage = i18n.language === 'zh_cn' ? 'chinese'
    : i18n.language === 'en' ? 'english'
      : i18n.language === 'es' ? 'spanish'
        : i18n.language === 'fr' ? 'french'
          : 'english'


  // Force leaving for returning visitors
  if (redirect) {
    window.location.href = 'https://www.responsibility.org/';
    return <div className="fixed w-screen h-screen bg-black" />
  }

  const setInput = (type: string, value: string) => {
    if (type === 'day') {
      setAge(ori => {
        const val = [...ori]
        val[2] = parseInt(value);
        return val;
      })
    }
    if (type === 'month') {
      setAge(ori => {
        const val = [...ori]
        val[1] = parseInt(value);
        return val;
      })
    }
    if (type === 'year') {
      setAge(ori => {
        const val = [...ori]
        val[0] = parseInt(value);
        return val;
      })
    }
  }

  const validateLda = async (birthDate: string, country: string) => {
    const url = 'https://api.b-fonline.com/api/validate_lda';
    const category = 'spirits';

    const data = {
      birth_date: birthDate,
      country: country,
      category: category
    };

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    return await res.json();
  }

  const onSubmit = async (e: any, age: (null | number)[], isSave: boolean) => {
    e.preventDefault();

    if (age[0] && age[0] < 1900) {
      return window.alert(t('Year cannot be smaller than 1900.'));
    }
    if (age[1] && (age[1] < 0 || age[1] > 12)) {
      return window.alert(t('Month input must between 1 - 12.'));
    }
    if (age[2] && (age[2] < 0 || age[2] > 31)) {
      return window.alert(t('Day input must between 1 - 31.'));
    }

    try {
      const countryCode = await getMyCountryCode();
      const adult = await validateLda(age.join('-'), countryCode);

      if (adult) {
        if (isSave) {
          localStorage.setItem('age-gate', 'passed');
        } else {
          localStorage.removeItem('age-gate');
        }
        window.scrollTo(0, 0);
        onPass();
      } else {
        localStorage.setItem('age-gate', 'failed');
        localStorage.setItem('age-gate-time', `${(new Date()).getTime()}`);
        setRedirect(true);
        window.confirm(t('Sorry you are not of legal drinking age.') || '')
        window.location.href = 'https://www.responsibility.org/';
      }
    } catch(e: any) {
      alert(e.message);
    }
  }

  const allInputed = age.every(v => v !== null);

  return (
    <div id="agegate-outer" className="bg-black">
      <div className="mx-auto mt-2 md:mt-2 pb-8 px-2 md:px-8 text-center">
        <LanguageButton
          className="absolute top-6 right-8 lg:top-8 lg:right-20"
          dropdownClassName={`origin-top absolute top-10 right-0 z-50 bg-black w-24 font-semibold`}
          darkMode={true}
        />
      </div>

      <div className="agegate-container pt-8">
        <div className="agegate-inner lg:mt-20">
          <img src="/assets/logo.png" className="mx-auto mb-8" alt="Naked Bottle Project Logo" width="286" />
          <form name="agegate" data-auto-tab="true">
            <h1 className="instructions mb-4 dm-sans font-semibold">
              {t('PLEASE CONFIRM YOUR')}
              {i18n.language !== 'zh_cn' && <span className="block lg:inline"> </span>}
              {t('AGE TO ENTER THIS SITE')}
            </h1>

            <div className="fields mt-8">
              <fieldset aria-label='fieldset' aria-labelledby='age-gate-enter' className="field-width agegate-fieldset" name="agegate-mmddyyyy">
                <div className="fieldwrapper"><label htmlFor="month" className="sr-only">{t('Month')}</label>
                  <input aria-label='month' aria-labelledby='age-gate-enter' type="number" name="agegate-month" onChange={(e) => setInput('month', e.target.value)}
                    placeholder="MM" required min="01" max="12" pattern="\d*" />
                </div>
                <div className="fieldwrapper"><label htmlFor="day" className="sr-only">{t('Day')}</label>
                  <input aria-label='day' aria-labelledby='age-gate-enter' type="number" name="agegate-day" onChange={(e) => setInput('day', e.target.value)}
                    placeholder="DD" required min="01" max="31" pattern="\d*" />
                </div>
                <div className="fieldwrapper"><label htmlFor="year" className="sr-only">{t('Year')}</label>
                  <input aria-label='year' aria-labelledby='age-gate-enter' type="number" name="agegate-year" onChange={(e) => setInput('year', e.target.value)}
                    placeholder="YYYY" required min="1900" max="2017" pattern="\d*" />
                </div>
              </fieldset>

              <div className="field-width selectwrapper hard-hidden">
                <label htmlFor="us" className="sr-only">{t('Country')}</label>
                <select name="agegate-country" data-default-iso="US" data-use-cf required>
                  <option value="US">{t('The United States')}</option>
                </select>
              </div>

              <div className="rememberwrapper dm-sans font-thin text-xs md:text-sm px-4">
                <p>{t('This site uses cookies.')}
                  {i18n.language !== 'zh_cn' && <span> </span>}
                  <a className="hover:text-secondary" href={`https://legal.brown-forman.com/cookie-policy/${urlLanguage}`}
                    target="_blank">{t('Cookie Policy')}</a>{t('. ')}
                  {i18n.language !== 'zh_cn' && <span> </span>}{t('I agree to the ')}<a
                    className="hover:text-secondary" href={`https://legal.brown-forman.com/terms-of-use/${urlLanguage}`} target="_blank">{t('terms of use')}</a>,{i18n.language !== 'zh_cn' && <span> </span>}{t('and the ')}<a
                      className="hover:text-secondary" href={`https://legal.brown-forman.com/privacy-policy/${urlLanguage}`} target="_blank">{t('privacy policy')}</a>{t('. ')}
                </p>
                <p>{t('This information will not be used for marketing purposes.')}</p>
              </div>

              <input type="hidden" name="agegate-category" value="spirits" />
              <div id="agegate-errors"></div>
              <div className="submitwrapper">
                <input disabled={!allInputed} id="age-gate-enter" type="submit" onClick={(e) => onSubmit(e, age, isSave)} className="uppercase" value={t("ENTER") || 'Enter'} />
              </div>

              <div id="remember-me-section" className={`${allInputed ? '' : 'hard-hidden'} dm-sans mt-8`}>
                <label htmlFor="remember-me" className="remember-me-container">
                  <input id="remember-me" type="checkbox" name="agegate-remember" className="uppercase"
                    onChange={(v) => setIsSave(v.target.checked)} />
                  <span className="text-xs mt-1 md:text-sm">{t('REMEMBER ME')}</span>
                  <span className="checkmark"></span>
                </label>
                <p className="mt-3 lg:mt-2 text-xs md:text-sm">{t('Do not choose this option if you use a shared computer')}</p>
              </div>

            </div>
          </form>
        </div>

        <p id="age-gate-slogan" className="eastman-compressed text-cetner mt-12 uppercase">
          {t('LIVE FREELY.')}
          {i18n.language !== 'zh_cn' && <span className="block lg:inline">{' '}</span>}
          {t('DRINK RESPONSIBLY.')}
        </p>

      </div>
      <Footer darkMode />
    </div>
  )
}

export default AgeGate;