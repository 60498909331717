import { motion, useInView } from "framer-motion";
import { useContext, useEffect, useRef, useState } from "react";
import { ViewContext } from '../App';

interface BackgroundSectionProps {
  id: string;
  backgroundUrl: string;
  titleOne: string;
  titleTwo: string | JSX.Element;
  content: string[];
}

const InViewOpacityDiv = ({ children, skipAnimate }: { children: JSX.Element, skipAnimate: boolean }) => {
  const duration = 0.8;
  const ref = useRef(null);
  const isInView = useInView(ref, {
    amount: 1,
    once: false,
  });

  return (
    <motion.div
      ref={ref}
      animate={skipAnimate ? { opacity: 1 } : { opacity: isInView ? 1 : 0 }}
      transition={{ ease: "easeOut", duration }}
    >
      {children}
    </motion.div>
  )
}

const Section5: React.FC<BackgroundSectionProps> = ({
  id,
  backgroundUrl,
  titleOne,
  titleTwo,
  content,
}) => {
  const { vh, scrollY, skipAnimate, fontSize, language } = useContext(ViewContext);
  const [dropIn, setDropIn] = useState(false);

  useEffect(() => {
    const curr = scrollY / vh;
    if (
      curr > 3.8 && curr < 3.9
      || curr > 6.4 && curr < 6.7
      || curr > 10.8 && curr < 10.9
    ) {
      setDropIn(true);
    } else {
      setDropIn(false);
    }
  }, [vh, scrollY]);

  return (
    <div
      id={id}
      className="section-container bg-cover bg-center section-container-5 flex flex-col items-center justify-center relative pb-12"
      style={{ background: `url('${backgroundUrl}') center center / cover`, height: 150 + (fontSize / 100 * 150) + 'vh' }}
    >
      {
        !skipAnimate &&
          <motion.img
            alt="A water drop into an image"
            src="/assets/drop-in-image.svg"
            style={{
              position: 'absolute',
              top: -2,
              left: '50%',
              marginLeft: -100,
              zIndex: 9999
            }}
            animate={{
              opacity: dropIn ? 1 : 0,
              y: dropIn ? [-20, 0, -20] : -20,
              scale: dropIn ? [1, 1.5, 1] : 1,
            }}
          />
      }

      <div className="title-container flex flex-col items-center mb-10">
        <InViewOpacityDiv skipAnimate={true}>
          <div className="eastman-compressed text-row font-black text-white uppercase section-5-title-1">{titleOne}</div>
        </InViewOpacityDiv>
        <InViewOpacityDiv skipAnimate={true}>
          <div
            className="text-row font-black text-custom-h3 text-transparent-border-white text-white uppercase section-5-title-2 -mt-2 md:-mt-4 text-center leading-tight">
            {titleTwo}</div>
        </InViewOpacityDiv>
      </div>
      <p className="content-text text-white text-center text-custom-h6 px-8 mx-auto"
        style={{ maxWidth: language === 'zh_cn' ? 680 : 620, letterSpacing: 0.6, lineHeight: 1.6 }}>
        {
          content.map((text, index) => (
            <InViewOpacityDiv skipAnimate={skipAnimate}>
              <p key={index} className="mb-10">{text}</p>
            </InViewOpacityDiv>
          ))
        }
      </p>

      <div id="section-container-5-exit-curve" className="exit-curve" style={{
        background: "url(assets/exit-curve.svg) center center / cover"
      }} />
    </div>
  )
}

export default Section5;