import { useTranslation } from 'react-i18next';
import LanguageButton from "./LanguageButton";

interface Props {
  darkMode?: boolean;
}

const Footer = ({ darkMode = false }: Props) => {
  const { t, i18n } = useTranslation();
  const backgroundColor = darkMode ? 'black' : 'white';
  const textColor = darkMode ? 'white' : 'black';

  const urlLanguage = i18n.language === 'zh_cn' ? 'chinese'
  : i18n.language === 'en' ? 'english'
  : i18n.language === 'es' ? 'spanish'
  : i18n.language === 'fr' ? 'french'
  : 'english'

  return (
    <footer className={`relative bg-${backgroundColor} md:mt-0`}>
      <div className="flex flex-col sm:flex-row items-center justify-center mx-auto py-4 text-center"
        style={{ fontWeight: 100 }}>
        <a href={`https://legal.brown-forman.com/cookie-policy/${urlLanguage}`}
          className={`py-3 border-t border-b border-${textColor} w-full sm:w-auto sm:border-none font-${textColor} font-bold hover:text-secondary px-4`}>{t('COOKIES')}</a>
        <span className="sm-hidden">|</span>
        <a href={`https://legal.brown-forman.com/terms-of-use/${urlLanguage}`}
          className={`py-3 border-b border-${textColor} w-full sm:w-auto sm:border-none font-${textColor} font-bold hover:text-secondary px-4`}>
          {t('TERMS OF USE')}</a>
        <span className="sm-hidden">|</span>
        <a href={`https://legal.brown-forman.com/privacy-policy/${urlLanguage}`}
          className={`py-3 border-b border-${textColor} w-full sm:w-auto sm:border-none font-${textColor} font-bold hover:text-secondary px-4`}>{t('PRIVACY POLICY')}</a>
        {/* <span className="sm-hidden">|</span>
        <a href="https://nutrition.brown-forman.com/en-us/jack_daniels"
          className={`py-3 border-b border-${textColor} w-full sm:w-auto sm:border-none font-${textColor} font-bold hover:text-secondary px-4`}>{t('NUTRITION')}</a> */}
        <span className="sm-hidden">|</span>
        <a href="https://www.jackdaniels.com/contact-us"
          className={`py-3 border-b border-${textColor} w-full sm:w-auto sm:border-none font-${textColor} font-bold hover:text-secondary px-4`}>{t('CONTACT US')}</a>
      </div>


      <div className="mx-auto mt-2 md:mt-2 pb-8 px-2 md:px-8 text-center">
        <LanguageButton
          className="relative"
          displayName
          dropdownClassName={`origin-top absolute top-10 left-0 right-0 z-50 bg-${backgroundColor} w-24 mx-auto font-semibold shadow`}
          darkMode={darkMode}
        />
      </div>

      <div className="mx-auto mt-2 md:mt-2 pb-8 px-2 md:px-8">
        <p className={`text-center text-${textColor} mx-4 font-normal uppercase`}>
          {t('JACK DANIEL DISTILLERY LYNCHBURG, TENNESSEE')}<br />
          {t('JACK, JACK DANIEL’S, OLD NO. 7, GENTLEMAN JACK, AND JD ARE TRADEMARKS OF JACK DANIEL’S PROPERTIES, INC. © 2023 JACK DANIEL’S PROPERTIES, INC.')}<br />
          {t('ALL RIGHTS RESERVED. ALL OTHER TRADEMARKS AND TRADE NAMES ARE PROPERTIES OF THEIR RESPECTIVE OWNERS.')}<br />
          {t('TO FIND OUT MORE ABOUT RESPONSIBLE CONSUMPTION, VISIT')} <a className="dm-sans underline text-xs lg:text-base hover:text-secondary"
            href="https://responsibility.org">RESPONSIBILITY.ORG</a> {t('AND')} <a className="dm-sans underline text-xs lg:text-base hover:text-secondary"
              href="https://OURTHINKINGABOUTDRINKING.COM">OURTHINKINGABOUTDRINKING.COM</a>
          <br />{t('PLEASE DO NOT SHARE OR FORWARD WITH ANYONE UNDER THE LEGAL DRINKING AGE.')}
        </p>
      </div>
    </footer>
  )
}

export default Footer;