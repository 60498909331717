import { motion } from "framer-motion";
import { useContext } from "react";
import { ViewContext } from '../App';
import { useTranslation } from 'react-i18next';

const Section2 = () => {
  const { vh, scrollY, skipAnimate, fontSize } = useContext(ViewContext);
  const { t, i18n } = useTranslation();

  const arrived = scrollY > vh * 1.1;

  return (
    <div
      className="section-container section-container-2 flex flex-col items-center justify-center px-4 mx-auto"
      style={{ height: 100 + (fontSize / 100 * 50) + 'vh' }}
    >
      <motion.div
        className="absolute top-0 left-0 w-full h-full bg-white origin-bottom z-10"
        animate={skipAnimate ? { scaleY: 0 } : { scaleY: arrived ? 0 : 1 }}
        transition={{ ease: "easeOut", duration: 1 }}
      />
      
      {/* <div className="scroll-overlay-2"></div> */}
      <div className="section-fix-full flex items-center justify-center">
        <h3 className="text-center text-custom-h5 top-0 section-2-text-style -mt-12">
          {t('Even small actions can create a ripple of change, this is the founding principle of The Naked Bottle Project. By removing the outer packaging we are giving you the choice to start your own ripple of change with Jack Daniel’s.')}
        </h3>
      </div>
    </div>
  )
}

export default Section2;