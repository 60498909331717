import { motion } from "framer-motion";
import { useContext } from "react";
import { ViewContext } from '../App';
import { useTranslation } from 'react-i18next';

const Section3 = () => {
  const { vh, scrollY, skipAnimate } = useContext(ViewContext);
  const { t, i18n } = useTranslation();
  const arrived = scrollY > vh * 2;

  return (
    <div className="section-container section-container-3 flex flex-col items-center justify-center min-h-screen overflow-hidden">
      <motion.div
        id="what-we-are-doing-cover"
        style={{
          background: 'radial-gradient(circle at center, transparent 5%, white 5%)',
          backgroundSize: '100% 100%',
          backgroundPosition: '50% 50%',
          width: '2000%',
          height: '2000%',
          zIndex: 1,
          position: 'absolute',
        }}

        animate={skipAnimate ? { scale: 1 } : { scale: arrived ? 1 : 0.05 }}
        transition={{ ease: "easeOut", duration: 1 }}
      />

      <div className="section-fix-full flex justify-center items-center text-center">
        <h1 style={{ bottom: 500 }} id="what-we-are-doing" className="eastman-compressed font-black">{t('WHAT WE’RE DOING')}</h1>
      </div>
    </div>
  )
}

export default Section3