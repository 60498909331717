import { motion, useInView } from "framer-motion";
import { useContext, useRef } from "react";
import { ViewContext } from '../App';

interface ThreeCircleProps {
  children?: JSX.Element;
  id: string;
}

const ThreeCircle: React.FC<ThreeCircleProps> = ({ id, children }) => {
  const { isSafari, skipAnimate, language } = useContext(ViewContext);
  const duration = 0.8;
  const ref = useRef(null);
  const isInView = useInView(ref, {
    amount: 0.8,
    once: false,
  });
  const _isInView = skipAnimate ? true : isInView;
  const finalScale = skipAnimate ? 1.5
    : language === 'fr' ? 1.4
      : language === 'es' ? 1.2
        : 1;

  const enlargeForSafari = isSafari ? 20 : 1;

  return (
    <div
      id={id}
      ref={ref}
      className="section-container section-container-4 flex flex-col items-center justify-center min-h-screen relative">
      <div className="section-fix-full overflow-hidden">
        <motion.div
          id="what-we-are-doing-cover"
          style={{
            background: 'radial-gradient(circle at center, transparent 5%, white 5%)',
            backgroundSize: '100% 100%',
            backgroundPosition: '50% 50%',
            width: 100 * enlargeForSafari + '%',
            height: 100 * enlargeForSafari + '%',
            zIndex: 1,
            position: 'absolute',
          }}

          animate={{ scale: _isInView ? 20 / enlargeForSafari : 1 / enlargeForSafari }}
          transition={{ ease: "easeOut", duration }}
        />
        <div className="absolute text-center">
          {children}
        </div>

        <motion.img
          animate={{ scale: _isInView ? 2 * finalScale : 0.25, translateY: _isInView ? 10 : 0, translateX: _isInView ? -10 : 0, rotate: _isInView ? 360 : 0 }}
          transition={{ ease: "easeOut", duration }}
          src="assets/ring-1.svg"
          id="section-container-4-ring-1"
          className="absolute container-ring-1"
          alt="A brown ring circle around the text"
        />
        <motion.img
          animate={{ scale: _isInView ? 2 * finalScale : 0.25, translateY: _isInView ? 10 : 0, translateX: _isInView ? -10 : 0, rotate: _isInView ? -360 : 0 }}
          transition={{ ease: "easeOut", duration }}
          src="assets/ring-2.svg"
          id="section-container-4-ring-2"
          className="absolute container-ring-2"
          alt="A blue ring circle around the text"
        />
        <motion.img
          animate={{ scale: _isInView ? 2 * finalScale : 0.25, translateY: _isInView ? 10 : 0, translateX: _isInView ? -10 : 0, rotate: _isInView ? 360 : 0 }}
          transition={{ ease: "easeOut", duration }}
          src="assets/ring-3.svg"
          id="section-container-4-ring-3"
          className="absolute container-ring-3"
          alt="A white ring circle around the text"
        />
      </div>
    </div>

  )
}

export default ThreeCircle