import { useContext, useEffect, useState } from "react";
import { ViewContext } from '../App';

interface MenuButtonProps {
  onClick: (open: boolean) => void;
}

const MenuButton = ({ onClick }: MenuButtonProps) => {
  const { vh, scrollY } = useContext(ViewContext);
  const [fill, setFill] = useState('#fff');
  const [langMenu, setLangMenu] = useState(false);

  useEffect(() => {
    if (
      scrollY / vh > 1 && scrollY / vh < 4.12
      || scrollY / vh > 5.5 && scrollY / vh < 6.62
      || scrollY / vh > 9.78 && scrollY / vh < 11.13
      || scrollY / vh > 12.5
    ) {
      setFill('#000');
    } else {
      setFill('#fff');
    }
  }, [scrollY])

  return (
    <button name="Menu Button" id="open-menu" className="fixed top-6 right-6" onClick={() => onClick(true)}>
      <svg id="menu-svg" xmlns="http://www.w3.org/2000/svg" width="27" height="19" viewBox="0 0 27 19">
        <title>Menu Button</title>
        <desc>Click to open menu.</desc>
        <rect id="Rectangle_36" data-name="Rectangle 36" width="27" height="3" rx="1.5" fill={fill} />
        <rect id="Rectangle_37" data-name="Rectangle 37" width="27" height="3" rx="1.5" transform="translate(0 8)"
          fill={fill} />
        <rect id="Rectangle_38" data-name="Rectangle 38" width="27" height="3" rx="1.5" transform="translate(0 16)"
          fill={fill} />
      </svg>
    </button>
  )
}

export default MenuButton;