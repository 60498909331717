import { motion, useInView } from "framer-motion";
import { useContext, useRef, useEffect } from "react";
import { ViewContext } from '../App';

import { useCallback, useState } from "react";
import { useTranslation } from 'react-i18next';

interface Props {
  id: string;
}

interface SubtitleProps {
  label: string;
  kind: string;
  srcLang: string;
  src: string;
}

const substitles: SubtitleProps[] = [
  {
    label: "English",
    kind: "subtitles",
    srcLang: "english",
    src: "/assets/vtt/video-english.vtt",
  },

  {
    label: "Français",
    kind: "subtitles",
    srcLang: "french",
    src: "/assets/vtt/video-france.vtt",
  },
  {
    label: "ESPAÑOL",
    kind: "subtitles",
    srcLang: "spanish",
    src: "/assets/vtt/video-spanish.vtt",
  },
  {
    label: "中文",
    kind: "subtitles",
    srcLang: "chinese",
    src: "/assets/vtt/video-chinese.vtt",
  },
]

const Section8 = ({ id }: Props) => {
  const { skipAnimate, language } = useContext(ViewContext);
  const [played, setPlayed] = useState(false);
  const videoRef = useRef<any>(null);
  const duration = 0.8;
  const ref = useRef(null);
  const isInView = useInView(ref, {
    amount: 0.28,
    once: false,
  });
  const _isInView = skipAnimate ? false : isInView;
  const { t, i18n } = useTranslation();
  const [playingVO, setPlayingVO] = useState(false);

  const isMobile = /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent);

  const playVoiceOver = () => {
    const audio: any = document.getElementById('audio-track');
    if (!audio) return;

    videoRef?.current?.load();
    videoRef?.current.play();

    audio?.load();
    audio?.play();
  }

  const playVideo = useCallback(() => {
    videoRef?.current.play();
  }, [videoRef]);

  useEffect(() => {
    if (!played) {
      const audio: any = document.getElementById('audio-track');
      if (!audio) return;
      audio?.pause();
    }
  }, [played])

  useEffect(() => {
    for (let i = 0; i < substitles.length; i++) {
      const mode = videoRef!.current.textTracks[i].language === language ? 'showing' : 'disabled';
      videoRef!.current.textTracks[i].mode = mode;
    }    
  }, [language, videoRef])

  return (
    <div id={id} className={`section-container section-container-8 text-white flex flex-col items-center justify-center py-8 overflow-hidden`}
      style={{ marginTop: '50vh', backgroundColor: skipAnimate ? 'black' : 'white' }}
    >
      <motion.div
        className="lead-circle lead-circle-8"
        animate={{ top: _isInView ? '80vh' : '-45vh', opacity: _isInView ? 1 : 0, scale: _isInView ? 1 : 0.0072 }}
        transition={{ ease: "easeOut", duration }}
      />

      <div ref={ref} className="z-50 relateive px-4 -mt-60 md:-mt-24">
        <div className="video-container max-w-5xl mb-8">
          <div className="relative" id="video-div">
            {(!played && !isMobile)
              && <img src="assets/play-icon.svg" id="video-play-icon" onClick={playVideo} alt="Video Play Button" />}
            <video
              muted={playingVO}
              poster="/assets/video-thumbnail.jpg"
              ref={videoRef}
              controls
              controlsList="nodownload"
              id="video"
              onPlay={() => setPlayed(true)} onPause={() => setPlayed(false)}

            >
              <source src="/assets/video.mp4" type="video/mp4" />
              {
                substitles.map((sub, i) => (
                  <track
                    key={i}
                    label={sub.label}
                    kind={sub.kind}
                    srcLang={sub.srcLang}
                    src={sub.src}
                    default={language === sub.srcLang}
                  />
                ))
              }
              <audio id="audio-track" onPlay={() => setPlayingVO(true)} onPause={() => setPlayingVO(false)}>
                {
                  language === 'fr' ? <source src="/assets/audio/fr.mp3" type="audio/mp3" />
                  : language === 'es' ? <source src="/assets/audio/es.mp3" type="audio/mp3" />
                  : language === 'zh_cn' ? <source src="/assets/audio/zh_cn.mp3" type="audio/mp3" />
                  : <source src="/assets/audio/en.mp3" type="audio/mp3" />
                }
              </audio>
              Your browser does not support the video tag.
            </video>
            {
              !played &&
                <button name="Speaking Button" onClick={playVoiceOver} className="absolute left-32 bottom-7 text-center mx-auto hover:bg-gray-800 hover:text-black rounded-full p-2 transition-all opacity-80 duration-100">
                  <img src="/assets/speaking-svgrepo-com.svg" className="w-6 h-6 fill-white"/>
                </button>
            }
          </div>
        </div>
        <div className="">
          <div className="title-container mb-4 max-w-5xl">
            <h1 className="text-custom-h3 text-center font-black uppercase" style={{ letterSpacing: 1.5 }}>{t('Crafting a')}<br />{t('sustainable future')}</h1>
          </div>
          <p className="content-text text-center text-custom-h6 px-4 max-w-3xl mx-auto mt-8"
            style={{ maxWidth: 549 }}>
            {t('Take a closer look at our efforts in transforming the way we conduct business using circularity principles, designing out waste to ensure we keep products and materials in use.')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Section8;