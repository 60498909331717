import { motion } from "framer-motion";
import { useState, useEffect, useContext, createContext } from 'react';
import './App.css';

import Menu from './components/Menu';
import MenuButton from "./components/MenuButton";

import Section1 from './components/Section1';
import Section2 from './components/Section2';
import Section3 from './components/Section3';
import ThreeCircle from './components/ThreeCircle';
import BackgroundSection from './components/BackgroundSection';
import Section8 from './components/Section8';
import Section11 from './components/Section11';
import Footer from './components/Footer';
import LeadCricle from './components/LeadCircle';
import AgeGate from "./components/AgeGate";
import Cookie from "./components/Cookie";
import LanguageButton from "./components/LanguageButton";
import configI18n from './locales/i18n';
import { useTranslation } from 'react-i18next';

configI18n();

export type LanguageType = 'en' | 'es' | 'fr' | 'zh_cn';

export const ViewContext = createContext<{
  vh: number,
  scrollY: number,
  isSafari: boolean,
  skipAnimate: boolean,
  language: LanguageType,
  setLanguage: any,
  fontSize: number,
}>({
  vh: 0,
  scrollY: 0,
  isSafari: false,
  skipAnimate: false,
  language: 'en',
  setLanguage: () => {},
  fontSize: 0,
});

let fontChangeTimeout: NodeJS.Timeout;

function App() {
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false)
  const [isSafari, setIsSafari] = useState(false)
  const [scrollY, setScrollY] = useState(0);
  const [agePass, setAgePass] = useState(false);
  const [skipAnimate, setSkipAnimate] = useState(false);
  const [acceptCookie, setAcceptCookie] = useState(false);
  const [language, setLanguage] = useState<LanguageType>('en');
  const [fontSize, setFontSize] = useState(0);
  const [vh, setVh] = useState(0);
  const { t, i18n } = useTranslation();

  const onScroll = (e: any) => {
    setScrollY(window.scrollY)
  }

  const onAcceptCookie = () => {
    localStorage.setItem('cookie', 'true');
    setAcceptCookie(true);
  }

  const onChangeFont = (v: any) => {
    clearTimeout(fontChangeTimeout);
    fontChangeTimeout = setTimeout(() => {
      setFontSize(typeof (v) === 'object' ? 0 : v);
    }, 500);
  }

  useEffect(() => {
    console.log('language', language);
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    if (window.innerHeight > vh) {
      setVh(window.innerHeight);
    }
  }, [window.innerHeight, vh])

  useEffect(() => {
    const root = document.documentElement;
    if (fontSize > 0) {
      setSkipAnimate(true);
    } else {
      setSkipAnimate(false);
    }
    root.style.setProperty('font-size', 16 * ((100 + fontSize / 2) / 100) + 'px');
  }, [fontSize])

  useEffect(() => {
    const isPassed = localStorage.getItem('age-gate');
    const acceptCookie = localStorage.getItem('cookie');
    setAcceptCookie(acceptCookie === 'true');

    // Moved to index.html
    // if (isPassed && isPassed === 'failed') {
    //   window.location.href = 'https://www.responsibility.org/';
    //   return;
    // }
    
    setLoading(false);
    window.addEventListener('scroll', onScroll);
    if (isPassed && isPassed === 'passed') setAgePass(true);
    const _isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    setIsSafari(_isSafari);

    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, [])

  if (loading) {
    return <div className="w-screen h-screen bg-black" />
  }

  if (!agePass) {
    return (
    <ViewContext.Provider value={{ vh, scrollY, isSafari, skipAnimate, language, setLanguage, fontSize }}>
      <AgeGate onPass={() => setAgePass(true)} />
    </ViewContext.Provider>
    )
  }

  return (
    <ViewContext.Provider value={{ vh, scrollY, isSafari, skipAnimate, language, setLanguage, fontSize }}>
      <Menu
        show={isOpen}
        skipAnimate={skipAnimate}
        close={() => setIsOpen(false)}
        onSkipAnimation={() => setSkipAnimate(ori => !ori)}
        onChangeFont={onChangeFont}
      />
      <MenuButton onClick={() => setIsOpen(true)} />

      <LanguageButton
        className="fixed top-6 right-20"
        dropdownClassName="absolute top-10 right-0 bg-white border-black z-50 font-semibold shadow text-center origin-top w-24"
      />

      {
        // !acceptCookie && <Cookie onAccept={onAcceptCookie} />
      }

      {
        !skipAnimate && <LeadCricle />
      }

      <div className="flex flex-col">
        <Section1 />
        <Section2 />
        <Section3 />
        {/* Section 4 */}
        <ThreeCircle id="section-4">
          <div className="-mt-4 lg:-mt-8 xl:-mt-12">
            <div className="eastman-compressed text-row font-black section-4-text-1">{t('ZERO')}</div>
            <div className="eastman-compressed text-row section-4-text-2 font-black text-transparent-border">
              {t('WASTE TO')}</div>
            <div className="eastman-compressed text-row section-4-text-2 font-black text-transparent-border">
            {t('LANDFILL')}</div>
          </div>
        </ThreeCircle>
        {/* Section 5 */}
        <BackgroundSection
          id="section-5"
          backgroundUrl='assets/bg-section4.jpg'
          titleOne={t('ZERO')}
          titleTwo={t('WASTE') || 'WASTE'}
          content={[
            t("Sugar maple charcoal filtering is sold as smoking pellets for barbecues."),
            t("Grain by-product from the distilling process is sold as cattle feed."),
            t("Used barrels are sold to other beverage alcohol manufacturers for aging product or used to make rustic furniture."),
            t("And there’s more that we do. To this day, Jack Daniel’s is zero waste to landfill, which we define as sending <1% of waste to landfill."),
          ]}
        />
        {/* Section 6 */}
        <ThreeCircle id="section-6">
          <>
            <div className="text-row font-black section-6-text-1">{t('PRESERVE')}</div>
            <div className="text-row font-black section-6-text-2 text-transparent-border">{t('AND')}</div>
            <div className="text-row font-black section-6-text-2 text-transparent-border">{t('PROTECT')}</div>
          </>
        </ThreeCircle>
        {/* Section 7 */}
        <BackgroundSection
          id="section-7"
          backgroundUrl='assets/bg-section7.jpg'
          titleOne={t('Natural')}
          titleTwo={t('Resources') || 'Resources'}
          content={[
            t("We conserve hundreds of acres of land to protect our Cave Spring, ensuring we have the unique water to continue to make great whiskey."),
            t("We partnered with The University of Tennessee on a long-term Tree Improvement Program to research and study white oak and sugar maple to ensure we have healthy supplies for the future."),
          ]}
        />
        {/* Section 8 */}
        <Section8 id="section-8" />
        {/* Section 9 */}
        <ThreeCircle id="section-9">
          <>
            <div className={`text-row font-black section-9-text-1 ${i18n.language !== 'zh_cn' ? 'text-transparent-border' : ''}`}>{t('RENEWABLE')}</div>
            <div className="text-row font-black section-9-text-1 text-transparent-border">{t('ELECTRICITY BY')}</div>
            <div className={`text-row font-black section-9-text-2 ${i18n.language !== 'zh_cn' ? '' : 'text-transparent-border'}`}>{t('2030')}</div>
          </>
        </ThreeCircle>
        {/* Section 10 */}
        <BackgroundSection
          id="section-10"
          backgroundUrl='assets/bg-section9.jpg'
          titleOne={t("All about")}
          titleTwo={<>{t('RENEWABLE_IN_ALL_ABOUT_RENEWABLE_ENERGY')}<br/>{t('ENERGY_IN_ALL_ABOUT_RENEWABLE_ENERGY')}</>}
          content={[
            t("As of April 2021, we’ve partnered with TVA and Duck River Electric to participate in the Green Invest Program. This partnership will allow Jack Daniel’s to obtain 20 MW of electricity from solar energy or about 75% of the distillery’s electrical consumption when the project becomes operational in 2026."),
            t("In 2022, we began working with 3 Rivers Energy Partners on a new anaerobic digester expected to be operational in 2024. The facility will convert spent grains from the Jack Daniel Distillery into renewable natural gas to help power the distillery and low-carbon natural fertilizer for local farms.")
          ]}
        />
        {/* Section 11 */}
        <Section11 />
        {/* Footer */}
        <Footer />
      </div>
    </ViewContext.Provider>
  );
}

export default App;
