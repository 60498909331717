import { motion, useInView } from "framer-motion";
import { useContext, useRef } from "react";
import { ViewContext } from '../App';
import { useTranslation } from 'react-i18next';

const Section11 = () => {
  const { skipAnimate } = useContext(ViewContext);
  const { t, i18n } = useTranslation();
  const duration = 0.8;
  const ref = useRef(null);
  const isInView = useInView(ref, {
    amount: 0.6,
    once: true,
  });
  const _isInView = skipAnimate ? true : isInView;

  return (
    <div ref={ref} style={{ height: '150vh' }} className="flex flex-col justify-end items-center relative">
      <motion.div
        className="absolute top-0 h-full w-full bg-white origin-bottom"
        animate={{ scaleY: _isInView ? 0 : 1 }}
        transition={{ ease: "easeOut", duration }}
      />

      <img id="footer-bottle" src="/assets/bottle.svg" style={{ margin: '0 auto' }} alt="Wine Bottle"></img>
      <div className="eastman-compressed text-center font-black footer-title mb-4 mt-16" style={{ letterSpacing: 1.5 }}>
        {t('START A RIPPLE')}<br />{t('OF CHANGE')}
      </div>
      <div className="eastman-compressed text-center font-black text-custom-h4 mt-40 mb-2 md:mb-8 uppercase" style={{ letterSpacing: 4 }}>
        {t('LIVE FREELY.')}
        {i18n.language !== 'zh_cn' && <span className="block lg:inline">{' '}</span>}
        {t('DRINK RESPONSIBLY.')}
      </div>
    </div>
  )
}

export default Section11;