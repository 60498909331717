const Toggle = ({ checked, onClick, disabled = false }: any) => {
  return (
    <div className="flex items-center justify-center">
      <label htmlFor="toggleB" className={`flex items-center ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
        <div className="relative">
          <input type="checkbox" id="toggleB" className="sr-only" checked={checked} onClick={onClick} />
          <div className="backdrop block bg-gray-200 w-10 h-6 lg:w-20 lg:h-12 p-0.5 rounded-full"></div>
          <div
            className="dot absolute bg-white shadow-sm rounded-full transition"
          ></div>
        </div>
      </label>

    </div>
  )
}

export default Toggle;